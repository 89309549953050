import ReactApexChart from "react-apexcharts";
import { CONFIG_CHART } from "./configChart";
import { ChartPropsInterface } from "./typings";

export default function CircleChart({ datas, labels, type }: ChartPropsInterface) {
    const configs = {
        options: {
            chart: CONFIG_CHART.chart,
            labels: labels
        },
        series: datas
    }
    return (
        <ReactApexChart
            options={configs.options}
            series={configs.series}
            type={type}
        />
    );
}
