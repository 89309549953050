import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "./configChart";
import { ChartPropsInterface } from "./typings";

export default function AreaChart({labels, datas}:ChartPropsInterface) {
    const configs = {
        series: datas,
        options: {
            chart: CONFIG_CHART.chart,
            yaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                        colors: ["#8c8c8c"],
                    },
                },
            },
            xaxis: {
                categories: labels,
            },
        },
    };
    return (
      <ReactApexChart
        className="full-width"
        options={configs.options}
        series={configs.series}
        type="area"
      />
  );
}
