import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { APP_API_URL } from "../../config/api";
import Loader from "../../components/loader/Loader";
import { useEffect } from "react";
import { HOME_PAGE } from "../../config/global";
import { useNavigate } from "react-router-dom";
import Redirect from "../../components/Redirect";

export default function SignWithAccessToken() {
    /**
     * Hooks
     */
    const navigate = useNavigate();
    const { signIn } = useAuth();
    const urlParams = new URLSearchParams(window.location.search)

    /**
     * Functions
     */
    const loginCall = () => {
        fetch(APP_API_URL + `user-detail`, {
            headers: {
                "Authorization": "Bearer " + urlParams.get("n")?.replaceAll(" ", "+"),
                "Content-type": "application/json"
            },
            method: "GET"
        }).then((response: any) => {
            return response.json()
        }).then((detailResponse: any) => {
            if (detailResponse.status === "OK") {
                signIn(
                    {
                        user: {
                            firstName: detailResponse.data.user.firstName,
                            username: detailResponse.data.user.username,
                            lastName: detailResponse.data.user.lastName,
                            email: detailResponse.data.user.email,
                            phone: detailResponse.data.user.phone,
                            accessToken: urlParams.get("n")?.replaceAll(" ", "+")
                        },
                        roles: detailResponse.data.roles
                    },
                    detailResponse.data.tokenExpireDate);
                navigate(HOME_PAGE, { replace: true });
                toast.success("Welcome !");
            }
            else {
                toast.error("Une erreur est survenu lors du chargement des données!");
            }
        }).catch((reason: any) => {
            toast.error("Une erreur est survenu lors du chargement des données!");
        })
    }

    useEffect(() => {
        loginCall()
    }, [])

    if (urlParams.get("n") === null) return <Redirect to="/404" />;

    return (
        <div className="grid h-screen place-items-center ">
            <Loader />
        </div>
    );
}
