import { UserRoles } from "./global"

export interface MenuInterface {
    icon: string,
    iconSolid?: boolean,
    title: string,
    link: string,
    pathname: string,
    roles?: UserRoles[],
    subMenus?: MenuInterface[],
}
export const MENUS: MenuInterface[] = [
    {
        icon: "chart",
        iconSolid: false,
        title: "menu:graph",
        link: "/dashboard",
        pathname: "dashboard",
        roles: []
    }
]
