import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "./configChart";
import { ChartPropsInterface } from "./typings";
import { TypeChart } from "./constants";

export default function ColumnChart({datas, labels}: ChartPropsInterface) {
    const configs = {
        options: {
            chart: CONFIG_CHART.chart,
            xaxis: {
                categories: labels
            },
        },
        series: datas,
    }
    return (
        <ReactApexChart
            options={configs.options}
            series={configs.series}
            type={TypeChart.bar}
            height={"100%"}
        />
    );
}
