import { useTranslation } from "react-i18next";
import Card from "../components/card/Card";
import AreaChart from "../components/chart/AreaChart";
import BarChart from "../components/chart/BarChart";
import CircleChart from "../components/chart/CircleChart";
import ColumnChart from "../components/chart/ColumnChart";
import LineChart from "../components/chart/LineChart";
import {TypeChart} from "../components/chart/constants";

function Dashboard() {
    const { t } = useTranslation();
    const areaChart = {
        datas: [
            {
                name: "Mobile apps",
                data: [350, 40, 300, 220, 500, 250, 400, 230, 500],
            },
            {
                name: "Websites",
                data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            },
        ],
        labels: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
        ]
    }
    const lineChart = {
        datas: [
            {
                name: "Series A",
                data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
            },
            {
                name: "Series B",
                data: [20, 29, 37, 36, 44, 45, 50, 58]
            }
        ],
        labels: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
        ]
    }
    const barChart = {
        datas: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        labels: [30, 40, 45, 50, 49, 60, 70, 91]
    }
    const pieChart = {
        datas: [44, 55, 15, 17],
        labels: ["A", "B", "C", "D"]
    }
    const columnCart = {
        labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
        datas: [
            {
                name: "Net Profit",
                data: [46, 57, 59, 54, 62, 58, 64, 60, 66]
            },
            {
                name: "Revenue",
                data: [74, 83, 102, 97, 86, 106, 93, 114, 94]
            },
            {
                name: "Free Cash Flow",
                data: [37, 42, 38, 26, 47, 50, 54, 55, 43]
            }
        ]
    }
    return (<div className={"grid grid-cols-1 md:grid-cols-2 gap-2"}>
        <Card title={t("page:dashboard:chart:line")}>
            <LineChart datas={lineChart.datas} labels={lineChart.labels}/>
        </Card>
        <Card title={t("page:dashboard:chart:bar")}>
            <BarChart datas={barChart.datas} labels={barChart.labels} title={"Bar Chart Datas"}/>
        </Card>
        <Card title={t("page:dashboard:chart:circle")}>
            <CircleChart datas={pieChart.datas} type={TypeChart.pie} labels={pieChart.labels}/>
        </Card>
        <Card title={t("page:dashboard:chart:column")}>
            <ColumnChart datas={columnCart.datas} labels={columnCart.labels}/>
        </Card>
        <Card title={t("page:dashboard:chart:area")}>
            <AreaChart datas={areaChart.datas} labels={areaChart.labels}/>
        </Card>
    </div>);
}

export default Dashboard;
