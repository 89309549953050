import { useMutate } from "../../hooks/useMutations";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { APP_API_URL } from "../../config/api";
import Loader from "../../components/loader/Loader";
import { useEffect } from "react";

function SignOut() {
    //Hooks
    const navigate = useNavigate();
    const auth = useAuth();
    const { mutateAsync: signOut, isLoading } = useMutate(APP_API_URL + `logout`);

    const logout = () => {
        let result = signOut({});
        result.then((response) => {
            if (response) {
                if (response.status === 200) {
                    auth.signOut();
                } else {
                    navigate("/500", { replace: true })
                }
            }
        }).catch((e) => {
            console.log(e)
            navigate("/500", { replace: true })
        });
    }
    useEffect(() => {
        if (!isLoading) {
            logout()
        }
    }, []);


    return (
        <div className="grid h-screen place-items-center ">
            <Loader />
        </div>
    );


}

export default SignOut;
