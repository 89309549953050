import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "./configChart";
import { ChartPropsInterface } from "./typings";
import { TypeChart } from "./constants";

export default function LineChart({labels, datas}: ChartPropsInterface) {
    const configs = {
        series: datas,
        options: {
            chart: CONFIG_CHART.chart,
            xaxis: {
                categories: labels,
            },
        },
    };
    return (
      <ReactApexChart
        className="full-width"
        options={configs.options}
        series={configs.series}
        type={TypeChart.line}
      />
  );
}
