import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import useStorage from "./useStorage";
import { useAuth } from "./useAuth";
import { useNavigate } from "react-router-dom";
import { RequestType } from "../config/global";

const useRequest = (url: string, type: RequestType) => {
    const { removeStorageUser } = useStorage();
    const navigate = useNavigate();
    let config: AxiosRequestConfig<any> = {};
    let { user: auth } = useAuth();
    if (auth) {
        config = {
            headers: {
                "Authorization": `Bearer ${auth?.user?.accessToken}`
            }
        }
    }

    const expiredSessionAction = (reason: any) => {
        if (reason?.response?.status === 403) {
            navigate("/403", { replace: true });
        } else {
            if (reason?.response?.status === 401) {
                removeStorageUser();
                if (auth) {
                    navigate("/logout", { replace: true });
                }
            } else {
                if (reason?.response?.status === 500) {
                    toast.error("Une erreur est survenue, nous travaillons pour un rétablissement à la normale au plus vite");
                }
            }
        }
    };

    const request = async (payloads: any | undefined): Promise<void | AxiosResponse<any, any>> => {
        switch (type) {
            case "delete":
                return axios.delete(url, config);
            case 'get':
                return axios.get(url, config).catch(expiredSessionAction);
            case 'patch':
                return axios.patch(url, payloads, config).catch(expiredSessionAction);
            case 'put':
                return axios.put(url, payloads, config).catch(expiredSessionAction);
            default:
                return axios.post(url, payloads, config).catch(expiredSessionAction);
        }
    }

    return {
        request
    }
}
export default useRequest;