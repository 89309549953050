import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "./configChart";
import { ChartPropsInterface } from "./typings";

export default function BarChart({datas, labels, title}: ChartPropsInterface) {
    const configs = {
        options: {
            chart: CONFIG_CHART.chart,
            xaxis: {
                categories: datas
            }
        },
        series: [
            {
                name: title,
                data: labels
            }
        ]
    }
    return (
        <ReactApexChart
            options={configs.options}
            series={configs.series}
            type="bar"
        />
    );
}
