import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Dashboard from "./pages/Dashboard";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./middleware/RequireAuth";
import ManageRoute from "./middleware/ManageRoute";
import Main from "./components/layout/Main";
import SignWithAccessToken from "./pages/auth/SignWithAccessToken";
import SignOut from "./pages/auth/SignOut";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";

function App() {
    const queryClient = new QueryClient()
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <LayoutProvider>
                    <Routes>
                        <Route element={<RequireAuth />}>
                            <Route element={<Main />}>
                                <Route path={"dashboard"} element={<Dashboard />} />
                            </Route>
                            <Route path="logout" element={<SignOut />} />
                        </Route>
                        <Route path="auth/login" element={<SignWithAccessToken />} />
                        <Route path="500" element={<ServerError />} />
                        <Route path="*" element={<ManageRoute />} />
                    </Routes>
                </LayoutProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
