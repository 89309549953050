export enum TypeChart {
    area = "area",
    line = "line",
    bar = "bar",
    pie = "pie",
    donut = "donut",
    histogram = "histogram",
    radialBar = "radialBar",
    scatter = "scatter",
    bubble = "bubble",
    heatmap = "heatmap",
    treemap = "treemap",
    boxPlot = "boxPlot",
    candlestick = "candlestick",
    radar = "radar",
    polarArea = "polarArea",
    rangeBar = "rangeBar"
}