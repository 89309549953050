import { useQuery } from "react-query";
import useRequest from "./useRequest";
import { toast } from "react-toastify";

export const useQueries = (url: string, queryKey: (string | number)[], options?: any) => {
    const { request } = useRequest(url, "get");
    return useQuery(queryKey, () => request(url), {
        onError: (error: any) => {
            toast.error(error.response.data.message);
        },
        ...options
    });
}
