import logo from "../../assets/images/logo-white.png"
import lang_en from "../../assets/images/flags/en.svg"
import lang_fr from "../../assets/images/flags/fr.svg"
import { useState } from "react";
import Dropdown from "../dropdown/Dropdown";
import MDIcons from "../icons/MDIcons";
import FontAwesomeIcons from "../icons/FontAwesomeIcons";
import { Link, useNavigate } from "react-router-dom";
import { useLayout } from "../../hooks/useLayout"
import BoxIcons from "../icons/BoxIcons"
import { APP_SLUG, LangType, LayoutType } from "../../config/global"
import i18n from "../../config/i18n"
import useStorage from "../../hooks/useStorage"
import { useAuth } from "../../hooks/useAuth";
import { ItemDropdownInterface } from "../dropdown/typings";
import { useQueries } from "../../hooks/useQueries";
import { AUTH_API_URL } from "../../config/api";
import TemplateDropdown from "../dropdown/TemplateDropdown";

export default function Header() {
    /**
     * Hooks
     */
    const { layout, changeLayout } = useLayout();
    const { user: auth } = useAuth();
    const navigate = useNavigate();
    const { getStorageLang, setStorageLang } = useStorage();
    const [lang, setLang] = useState(getStorageLang() === LangType.fr ? lang_fr : lang_en);
    const [openApp, setOpenApp] = useState(false);


    /**
     * Query
     */
    const {
        data: apps,
        isLoading
    } = useQueries(AUTH_API_URL + `auth-apps/${APP_SLUG}`, ["auth-apps"], {
        enabled: openApp
    });

    /**
     * Datas
     */
    const languageItems = [
        {
            onClick: () => handleChangeLanguage(LangType.fr, lang_fr),
            className: '',
            children: () => (
                <><img src={lang_fr} alt="user-french" className="mr-1" height="16" /> <span>Français</span></>
            )
        },
        {
            onClick: () => handleChangeLanguage(LangType.en, lang_en),
            className: '',
            children: () => (
                <><img src={lang_en} alt="user-english" className="mr-1" height="16" /> <span>English</span></>
            )
        }
    ]

    const profileItems: ItemDropdownInterface[] = [
        {
            onClick: () => navigate("/profile"),
            className: '',
            children: () => (<><BoxIcons icon={"user"} className="font-size-16 align-middle mr-2" /><span>Profile</span></>)
        },
        {
            onClick: () => navigate("/logout", { replace: true }),
            className: '',
            children: () => (<><BoxIcons icon={"power-off"} className="font-size-16 align-middle mr-2 text-red-500" /><span
                className="text-red-500">Déconnexion</span></>)
        },
    ]

    const layoutItems: ItemDropdownInterface[] = [
        {
            onClick: () => { },
            className: 'hover:bg-transparent hover:cursor-default',
            children: () => (<span className="ml-2">Menu</span>),
            divider: true
        }, {
            onClick: () => changeLayout(LayoutType.vertical),
            className: '',
            children: () => (
                <>{layout === LayoutType.vertical && <BoxIcons icon={"check"} />}
                    <span className="ml-2">Vertical</span></>
            )
        },
        {
            onClick: () => changeLayout(LayoutType.horizontal),
            className: '',
            children: () => (
                <>{layout === LayoutType.horizontal && <BoxIcons icon={"check"} />}
                    <span className="ml-2">Horizontal</span></>
            )
        }
    ]

    /**
     * Functions
     */
    const handleOpenMenu = () => {
        if (layout === LayoutType.horizontal) {
            let topnav = document.getElementById("topnav-menu-content");
            if (topnav) {
                if (topnav.className.includes("show")) {
                    topnav.className = topnav.className.replace("show", "");
                } else {
                    topnav.className = topnav.className.concat("show");
                }
            }
        } else {
            if (document.body.className.includes("sidebar-enable")) {
                document.body.className = "";
            } else {
                document.body.className = "sidebar-enable"
            }
        }
    }

    const handleChangeLanguage = (lang: LangType, icon: any) => {
        i18n.changeLanguage(lang)
        setLang(icon)
        setStorageLang(lang)
    }

    return (<header id="page-topbar">
        <div className="navbar-header">
            <div className="flex justify-center items-center">
                <div className="navbar-brand-box">
                    <a href="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="" className="h-10" />
                        </span>
                        <span className="logo-lg">
                            <img src={logo} alt="" className="h-10" />
                        </span>
                    </a>
                </div>
                <button type="button" onClick={handleOpenMenu}
                    className="btn btn-sm px-3 font-size-16 lg:hidden header-item text-white">
                    <FontAwesomeIcons icon={"bars"} className={"fa-fw"} />
                </button>
            </div>
            <div className="flex justify-center items-center">
                <Dropdown buttonClassName={"header-item noti-icon"}
                    buttonChildren={<BoxIcons icon="layout" />}
                    items={layoutItems} />
                <Dropdown buttonClassName={"header-item noti-icon"}
                    buttonChildren={<img id="header-lang-img" src={lang} alt="Header Language" />}
                    items={languageItems} className={'language'} />
                <TemplateDropdown open={openApp} setOpen={setOpenApp} buttonChildren={<BoxIcons icon={"customize"} />} buttonClassName={"header-item noti-icon"}
                    className={`dropdown inline-block`}>
                    <div className="dropdown-menu-lg">
                        <div className="grid grid-cols-2 px-4 justify-items-center gap-4">
                            {
                                !isLoading ? (apps?.data.data.map((app: any, index: number) => {
                                    return (
                                        <Link to={`/connect/${app.slug}`} key={index}
                                            className={`py-3 w-full grid justify-items-center hover:bg-opacity-5 hover:bg-gray-500`}>
                                            <img src={app.logoUrl} className="h-6 w-6 object-cover" alt={app.name} />
                                            <span>{app.name}</span>
                                        </Link>
                                    );
                                })) : (
                                    <>
                                        <div className="py-6 w-full bg-soft-secondary animate-pulse"></div>
                                        <div className="py-6 w-full bg-soft-secondary animate-pulse"></div>
                                        <div className="py-6 w-full bg-soft-secondary animate-pulse"></div>
                                    </>
                                )
                            }
                            {
                                apps?.data.data.length === 0 && (
                                    <div className="col-span-2 text-center">
                                        <BoxIcons icon={"info-circle mr-2"} />
                                        <span>Aucune application</span>
                                    </div>)
                            }
                        </div>
                    </div>
                </TemplateDropdown>
                <Dropdown buttonClassName={"header-item noti-icon my-auto flex items-center"}
                    buttonChildren={<><span className="rounded-full header-profile-user bg-light inline-block" >{auth?.user.lastName[0]}</span>
                        <span className="hidden xl:inline-block ml-1"
                            key="username">{auth?.user.lastName} </span>
                        <MDIcons icon={"chevron-down"} className={"hidden xl:inline-block"} /></>}
                    items={profileItems} />

            </div>
        </div>
    </header>
    )
}
